import type { Events } from 'webview/constants/events'
import type { OptionalParams } from 'webview/types'

import { isIos } from '../constants'
import type { AndroidProps, IosProps } from '../types'
import { androidEvent } from './androidEvent'
import { iosEvent } from './iosEvent'

export const NavigateToListener = 'navigator'

export function sendAppEventFactory<T>({
	command,
	listener,
	optionalParams,
}: IosProps<T> | AndroidProps<T>) {
	if (isIos()) {
		return iosEvent({ command, listener, optionalParams } as IosProps<T>)
	}

	return androidEvent({ command, optionalParams } as AndroidProps<T>)
}

interface SendAppEvent<T> {
	event: Events
	optionalParams?: OptionalParams<T>
	listener?: string
}

export function sendAppEvent<T>({
	event,
	optionalParams,
	listener,
}: SendAppEvent<T>) {
	sendAppEventFactory({
		command: event,
		listener: listener ?? NavigateToListener,
		optionalParams,
	})
}
