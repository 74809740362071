import { useMemo } from 'react'
import {
	APPLICATION_LAYER_ID,
	DIALOGS_LAYER_ID,
	PAGE_CONTENT_ID,
	TOP_LAYER_ID,
} from 'utils/layoutLayers/constants'

export function useLayoutLayers() {
	return useMemo(() => {
		if (typeof document === 'undefined') {
			return {
				pageContent: null,
				application: null,
				topLayer: null,
				dialogs: null,
			}
		}
		return {
			pageContent: document.getElementById(PAGE_CONTENT_ID),
			application: document.getElementById(APPLICATION_LAYER_ID),
			topLayer: document.getElementById(TOP_LAYER_ID),
			dialogs: document.getElementById(DIALOGS_LAYER_ID),
		}
	}, [])
}
